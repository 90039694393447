import React from "react";
import { StyledGridRow } from "../../commons/Grid";
import {
  StyledImpactCards,
  StyledImpactCardsCard,
  StyledImpactCardsCardIcon,
  StyledImpactCardsCardInfo,
  StyledImpactCardsCards,
  StyledImpactCardsCardText,
  StyledImpactCardsCardValue,
} from "./style";

const ImpactCards = ({ cards }) => {
  return (
    <StyledImpactCards>
      <StyledGridRow noMargin>
        <StyledImpactCardsCards>
          {cards.map((card, index) => (
            <StyledImpactCardsCard key={index}>
              <StyledImpactCardsCardIcon bg={card.icon} />
              <StyledImpactCardsCardInfo>
                <StyledImpactCardsCardText>{card.text}</StyledImpactCardsCardText>
                <StyledImpactCardsCardValue>
                  {card.value}
                </StyledImpactCardsCardValue>
              </StyledImpactCardsCardInfo>
            </StyledImpactCardsCard>
          ))}
        </StyledImpactCardsCards>
      </StyledGridRow>
    </StyledImpactCards>
  );
};

export default ImpactCards;
